$blue: #0095db;
$primary: #1251b5;
$primary-disabled: #3a5b90;
$blue-grey: #4d4e53;
$warm-grey: #8f8f94;
$white-blue: #ced4da;
$topnav-background-color: white;
$modal-header-color: white;
$warning: #cb6423;
$error-disabled: #8a4335;
$error-color: #c4001a;
$success-color: #47962d;
$green: #13a538;
$excel: green;
$excel-disabled: #54985e;
