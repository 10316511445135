@import "colors";
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.modal-xl .modal-dialog {
  max-width: 1350px;
}

.modal-icon {
  position: absolute;
  color: white;
  border-radius: 50%;
  background-color:  #e83448;
  border: 5px solid white;
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(50% - 2.5rem);
  top: -2.5rem;
}

.modal-status {
  position: absolute;
  color: white;
  border-radius: 5rem;
  border: 5px solid white;
  height: 3rem;
  width: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(50% - 6rem);
  top: -1.5rem;
  padding: 0 1.5rem;
  font-weight: 900;
  font-size: 1.1rem;
}

.modal-status.approved {
  background-color: $green;
}

.modal-status.rejected {
  background-color: $primary;
}

.modal-status.requested {
  background-color: $blue;
}

.modal-header {
  justify-content: center;
  padding-top: calc(3.5rem - 5px);
  position: relative;
  background-color: $modal-header-color;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5rem;
}

.modal-header.parameters {
  padding-top: calc(2.5rem - 5px);
  flex-direction: column;
  align-items: center;
}

.modal-title {
  font-weight: 700;
}

.modal-header-arrow {
  border-width: 0.5rem;
  border-color: $modal-header-color;
  border-style: solid;
  bottom: -0.4rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
  position: absolute;
  left: calc(50% - 0.5rem);
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  background-image: url('../assets/images/modal-close-button.png');
  background-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  background-repeat: no-repeat;
  background-position: center;
}

.modal-footer {
  justify-content: center;
  border: 0;
}

.modal-body {
  padding: 2rem 5rem;

  .close {
    padding: $modal-header-padding;
    outline: none !important;
    // auto on the left force icon to the right even when there is no .modal-title
    margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
  }
}

.request-modal-header {
  padding: 1.5rem 4rem;
  background-color: rgba(217, 218, 219, 0.3);
}

.request-separator {
  margin: 1.5rem -15px 3rem -15px;
}

.modal {
  display: flex !important;
  .modal-dialog {
    flex-basis: 100%;
  }
}
