@import 'modal';
@import 'colors';
@import '~font-awesome/css/font-awesome.css';


@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
	font-family: "DINPro-CondBold";
	src: url("../assets/DINPro-CondBold.woff") format("woff")
}

* {
	transition: all 0.3s ease-in-out;
	font-max-size: 13px !important;
}

html {
	overflow-y: scroll;
}

.font-grey {
	color: $warm-grey;
}

@media screen and (max-width: 768px) {
	.bodytablet {
		padding-top: 11rem;
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	appearance: none;
	margin: 0;
}

.custom-rt-font {
	font-family: 'DINPro-CondBold', 'Roboto', sans-serif;
}

.bg-info-rt {
	background-color: #BFDBFE !important;
}

.w-5 {
	width: 5% !important;
}

.w-8 {
	width: 8% !important;
}

.w-10 {
	width: 10% !important;
}

.w-12 {
	width: 12% !important;
}

.w-15 {
	width: 15% !important;
}

.w-17 {
	width: 17% !important;
}

.w-20 {
	width: 20% !important;
}

.w-25 {
	width: 25% !important;
}

.w-28 {
	width: 28% !important;
}

.w-30 {
	width: 30% !important;
}

.w-33 {
	width: 33.33333333333333% !important;
}

.w-35 {
	width: 35% !important;
}

.w-40 {
	width: 40% !important;
}

.w-47 {
	width: 47% !important;
}

.w-55 {
	width: 55% !important;
}

.w-57 {
	width: 57% !important;
}

.w-60 {
	width: 60% !important;
}

.w-65 {
	width: 65% !important;
}

.w-70 {
	width: 70% !important;
}

.w-80 {
	width: 80% !important;
}

.w-85 {
	width: 85% !important;
}

.w-90 {
	width: 90% !important;
}

.dropdown-toggle::after {
	content: none !important;
}

.h-92 {
	height: 92% !important;
}

.bg-dark-rt {
	background-color: #484848 !important;
	color: white !important;
}

.bg-readonly {
	background-color: #F4F4F4 !important;
	color: #949494 !important;
	cursor: not-allowed;
}
.not-allowed {
	cursor: not-allowed !important;
}


.sb-checkbox {
	margin: 10px;
}

$mapColor: ('green', #66bb6a, #fff),
	('red', #E3021B, #fff),
	('blue', #5c6Bc0, #fff),
	('orange', #ffa726, #fff);

.sb-checkbox {
	display: inline-block;
	font-size: 0;

	&__input {
		display: none;

		&:checked {
			+ .sb-checkbox__label {
				&:after {
					opacity: 1;
				}
			}
		}
	}

	&__label {
		display: inline-block;
		width: 17px;
		height: 17px;
		position: relative;
		cursor: pointer;

		&:before {
			content: '';
			width: 17px;
			height: 17px;
			border: 2px solid;
			box-sizing: border-box;
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 2px;

		}

		&:after {
			content: 'done';
			font-family: 'Material Icons';
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			width: 17px;
			height: 17px;
			display: block;
			border-radius: 2px;
			overflow: hidden;
			text-align: center;
			opacity: 0;
			transition: .2s opacity;
		}

		@each $class, $color1, $color2 in $mapColor {
			&--#{$class} {
				&:before {
					border-color: #868686;

				}

				&:after {
					background-color: $color1;
					border-color: $color1;
					color: $color2;
					outline: none;
				}
			}
		}
	}
}
