/* Roboto font + ZMDI icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap');


$color-rt-red: #e3021b;
$color-black: #000000;
$color-white: #ffffff;
$color-text: #333333;
$color-error-red: #bf0000;
$color-green: #217f30;
$color-rt-secondary1: #eee047;
$color-rt-secondary2: #dbd498;
$color-rt-secondary3: #9ab8ad;
$color-rt-secondary4: #bb9979;
$color-rt-secondary5: #d0af21;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-dinpro-condbold: 'DINPro-CondBold';

/* Basic RT layout */
html,
body {
  min-height: 100%;
  font-size: 14px;
  font-family: $font-family-roboto;
}
body {
  background-color: #f4f4f4;
  margin: 0;
}

/* RT headings */
h2.rt-heading {
  color: $color-text;
  font-family: $font-family-dinpro-condbold;
  margin: 0 0 30px 0;
  font-size: 2.286rem;
  @media (max-width: 426px) {
    text-align: center;
    font-size: 2rem;
  }
}
h3.rt-heading {
  color: $color-text;
  font-family: $font-family-dinpro-condbold;
  margin: 20px 0;
  font-size: 2rem;
}
h4.rt-heading {
  color: $color-text;
  font-family: $font-family-dinpro-condbold;
  margin: 10px 0;
  font-size: 1.714rem;
}

/* RT Links */
a.rt-link {
  color: $color-text;
  text-decoration: none;
  &.red {
    color: $color-rt-red;
  }
  &:hover {
    color: $color-error-red;
  }
  .zmdi-open-in-new,
  .zmdi-arrow-right {
    margin-left: 5px;
  }
  .zmdi-arrow-left {
    margin-right: 5px;
  }
}

/* RT Buttons */
button.rt-button {
  font-size: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 15px;
  padding: 5px 20px;
  &.big {
    padding: 8px 20px;
  }
  &.round {
    padding: 5px 8px;
    border-radius: 50%;
    .zmdi {
      font-size: 1.143rem;
      margin: 0;
    }
    &.big {
      padding: 7px 10px;
      .zmdi {
        font-size: 1.286rem;
        margin: 0;
      }
    }
  }
  border-color: #c4c4c4;
  background-color: #ffffff;
  color: #333333;
  &:hover {
    border: 1px solid #000000;
    background-color: #000000;
    color: #ffffff;
  }
  &.green {
    border-color: #217f30;
    background-color: #ffffff;
    color: #217f30;
    &:hover {
      border: 1px solid #005f21;
      background-color: #005f21;
      color: #ffffff;
    }
  }
  &.red {
    border-color: #b21d1f;
    background-color: #ffffff;
    color: #b21d1f;
    &:hover {
      border: 1px solid #860000;
      background-color: #860000;
      color: #ffffff;
    }
  }
  &.active {
    border: 1px solid #252525;
    background-color: #252525;
    color: #ffffff;
    &:hover {
      border: 1px solid #000;
      background-color: #000000;
    }
    &.green {
      border: 1px solid #217f30;
      background-color: #217f30;
      &:hover {
        border: 1px solid #005f21;
        background-color: #005f21;
      }
    }
    &.red {
      border: 1px solid #b21d1f;
      background-color: #b21d1f;
      &:hover {
        border: 1px solid #860000;
        background-color: #860000;
      }
    }
  }
  &:disabled,
  &:hover:disabled {
    background-color: #eeeeee !important;
    color: #aaaaaa !important;
    border: 1px solid #c4c4c4 !important;
  }
  .zmdi {
    margin-right: 5px;
  }
}
